/*=========================================================================================
  File Name: faqState.js
  Description: FAQ State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    faqs: [{
            id: 1,
            question: "What is ancestry?",
            ans: "Ancestry is a comprehensive platform for all related and covered applications that would be able to create a general consensus and contemporary training of information technology in sales and marketing of these products through Smart Contract."
        },
        {
            id: 2,
            question: "What is the minimum amount of starting on this platform?",
            ans: "The minimum starting amount is $50 (USDT-TRC20), access every single application via instalment payment processing system."
        },
        {
            id: 3,
            question: "What is ancestry-connected platforms?",
            ans: "Currently, the Trade Center service is connected to the Central Center application, and in the future, Game Center and other service sections will be connected to the Center platform."
        },
        {
            id: 4,
            question: "What is the upper limit on the value of shares received from the pool?",
            ans: "The upper limit receiving from the pool is adjusted intelligently, and the more the ECS is frozen, the ceiling for the value of shares received is adjusted according to the pool inventory."
        },
        {
            id: 5,
            question: "How does ancestry make money?",
            ans: "Each person can earn up to ten times of their own investment in the center."
        },
        {
            id: 6,
            question: "the money invested in Ancestry would be recoverable?",
            ans: "All expenses received in the center are for the variety of receiving services in game and trading platforms, etc., and the person has received the services rendered for investing, this money is non-refundable"
        },
        {
            id: 7,
            question: "How much do shareholders receive from this platform?",
            ans: "No matter how much fund is raised on the Centre platform through all the different applications, ten percent will be deposited to the company's digital stockholders every week. Of course, the more the public effort to raise fund, the higher the dividends will be."
        },
        {
            id: 8,
            question: "What steps shall be done to receive participation dividends?",
            ans: "in order to receive participation dividends from this platform, you should be freezing your digital token."
        },
    ]
};
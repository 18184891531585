<template>
  <div class="tron-component" />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "TronComponent",
  data() {
    return {
      tronWeb: ""
    };
  },
  computed: {
    ...mapGetters({
      getDefaultAddress: "tronBot/getDefaultAddress",
      getLoginStatus: "tronBot/getLoginStatus"
    })
  },
  mounted() {
    this.setTronWebObj();
  },
  methods: {
    // Vuex Map Actions
    ...mapActions({
      setTronAccessStatus: "tronBot/setTronAccessStatus",
      setTronWebObject: "tronBot/setTronWebObject",
      setTronConnectionStatus: "tronBot/setTronConnectionStatus",
      setDefaultAddress: "tronBot/setDefaultAddress",
      setLoginStatus: "tronBot/setLoginStatus"
    }),
    //

    // Vuex Map Actions
    ...mapMutations({
      LOGOUT: "auth/LOGOUT"
    }),
    //

    backToLogin() {
      sessionStorage.clear();

      if (
        this.$route.name != "login" &&
        this.$route.name != "page-payment" &&
        this.$route.name != "exchange" &&
        this.$route.name != "sync"
      ) {
        location.reload();
        this.$router.push({ name: "login" });
      }
    },

    setTronWebObj() {
      try {
        const intvl = setInterval(() => {
          if (window.tronWeb !== undefined) {
            this.setTronAccessStatus(true);
            if (window.tronWeb.ready) {
              this.tronWeb = window.tronWeb;
              if (this.getDefaultAddress != this.tronWeb.defaultAddress) {
                this.$emit("onGetTronObj", this.tronWeb);
                this.setTronWebObject(this.tronWeb);
                this.setTronConnectionStatus(true);
                this.setLoginStatus(true);

                this.setDefaultAddress(this.tronWeb.defaultAddress);
              }
            } else {
              // TRONWEB IS ACCESSIBLE BUT NOT CONNECTED(NOT LOGGED IN)!

              this.setTronConnectionStatus(false);
              this.setLoginStatus(false);

              this.backToLogin();
            }
          } else {
            // location.reload();
            this.setTronAccessStatus(false);
            sessionStorage.clear();
          }
        }, 2000);
      } catch (e) {
        // console.log(e)
      }
    }
  },
  watch: {
    getDefaultAddress(newVal, oldVal) {
      if (
        this.getDefaultAddress.base58 &&
        oldVal.base58 &&
        newVal.base58 != oldVal.base58
      ) {
        if (
          this.$route.name != "login" &&
          this.$route.name != "page-payment" &&
          this.$route.name != "exchange" &&
          this.$route.name != "sync"
        ) {
          location.reload();
          sessionStorage.clear();
          this.$router.push({ name: "login" });
        }
      }
    }
  }
};
</script>

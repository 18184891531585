/*=========================================================================================
  File Name: Contract.js
  Description: Contract
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './contractState.js'
import getters from './contractGetters.js'
import actions from './contractActions.js'
import mutations from './contractMutations.js'

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
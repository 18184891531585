// /*=========================================================================================
//   File Name: paymentActions.js
//   Description: Payment Actions
//   ----------------------------------------------------------------------------------------
//   Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
//   Author: Daniyal
//   Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================*/

import api from "../../axios";

export default {
    // Pay
    async pay({ commit }, payload) {
        try {
            await api.post("invests/pay", payload);

            //
            return true;
        } catch (error) {
            console.error(error);
            return false
        }
    },
    //

    // Burn
    async burn({ commit }, payload) {
        try {
            await api.post("invests/burn", payload);

            //
            return true;
        } catch (error) {
            console.error(error);
        }
    },
    //

    // Freeze
    async freeze({ commit }, payload) {
        try {
            await api.post("invests/freeze", payload);

            //
            return true;
        } catch (error) {
            console.error(error);
        }
    },
    //

    // Get Invest List
    async getInvests({ commit }) {
        try {
            const res = await api.get("invests/index");

            //
            let invests = res.data.result.list;
            let mine = res.data.result.mine;
            //

            //
            commit("setInvests", invests);
            commit("setInvestMine", mine);
            //
        } catch (error) {
            console.error(error);
        }
    },
    //

    // Get Benefits
    async getBenefits({ commit }) {
        try {
            const res = await api.get("invests/benefits");

            //
            let benefits = res.data.result.list;
            let mine = res.data.result.mine;
            //

            //
            commit("setBenefits", benefits);
            commit("setBenefitsMine", mine);
            //
        } catch (error) {
            console.error(error);
        }
    },
    //

    // Get Benefits
    async getWithdraws({ commit }) {
        try {
            const res = await api.get("withdraws/index");

            //
            let withdraws = res.data.result.list;
            let mine = res.data.result.mine;
            let availableBalance = res.data.result.availableBalance;
            //

            //
            commit("setWithdraws", withdraws);
            commit("setWithdrawsMine", mine);
            commit("setAvailableBalance", availableBalance);
            //
        } catch (error) {
            console.error(error);
        }
    },
    //

    // Withdrow Request
    async withdrow({ commit }) {
        try {
            await api.post("withdraws/withdraw-all");

            //
            return true;
            //
        } catch (error) {
            console.error(error);
            return false
        }
    },

    // Unfreez Request
    async unFreez({ commit }, payload) {
        try {
            await api.post("withdraws/unfreeze", payload);

            //
            return true;
            //
        } catch (error) {
            console.error(error);
        }
    },

    // Get Invest Info
    async getInvestInfo({ commit }, payload) {
        try {
            const res = await api.post('site/get-invest-info', payload)

            //
            let info = res.data.result;

            //
            commit('setInvestInfo', info)

            //
            return true
        } catch (error) {
            return false
        }
    }
    //
};
/*=========================================================================================
  File Name: ticket.js
  Description: Ticket
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './networkModuleState.js'
import mutations from './networkModuleMutations.js'
import actions from './networkModuleActions.js'
import getters from './networkModuleGetters.js'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
/*=========================================================================================
  File Name: tronBotActions.js
  Description: Tron Bot Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
==========================================================================================*/

export default {
    setTronWebObject({ commit }, payload) {
        commit("setTronWebObject", payload);
    },
    setTronAccessStatus({ commit }, payload) {
        commit("setTronAccessStatus", payload);
    },
    setTronConnectionStatus({ commit }, payload) {
        commit("setTronConnectionStatus", payload);
    },
    setDefaultAddress({ commit }, payload) {
        commit("setDefaultAddress", payload);
        sessionStorage.setItem('defaultAddress', JSON.stringify(payload))
    },
    setLoginStatus({ commit }, payload) {
        commit("setLoginStatus", payload);
    }
};
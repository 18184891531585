/*=========================================================================================
  File Name: faq.js
  Description: FAQ Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './faqState'
import getters from './faqGetters'

export default {
    namespaced: true,
    state,
    getters
}
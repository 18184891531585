/*=========================================================================================
  File Name: ticketMutations.js
  Description: Ticket Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    SET_TICKETS(state, responce) {
        return (state.tickets = responce);
    },

    SET_NEW_TICKET(state, responce) {
        state.tickets.unshift(responce);
    },

    SHOW_TICKET(state, responce) {
        return (state.selectedTicket = responce);
    },

    SET_NEW_ANSWER(state, responce) {
        state.selectedTicket.content.push(responce);
    },

    SET_DEPARTMENTS(state, responce) {
        return (state.departments = responce);
    },

    SET_TICKET_LASTPAGE(state, responce) {
        return (state.lastPage = responce);
    }
};
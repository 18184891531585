import api from "../../axios";

export default {
    async getToken({ commit }, payload) {
        try {
            const res = await api.post("login", payload);

            //
            let auth = res.data.result.auth;
            let user = res.data.result.user;
            //

            //
            commit("SET_AUTH", auth);
            commit("SET_USER", user);
            commit("SET_TOKEN", auth.access_token);
            //

            sessionStorage.setItem("user", JSON.stringify(user));
            sessionStorage.setItem("token", auth.access_token);

            if (user.two_fa_url) {
                return user.two_fa_url;
            }
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    },

    async saveTwoFa({ commit }, payload) {
        try {
            await api.post("site/save-two-fa-key", payload);

            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    },

    async getDashboardData({ commit }) {
        try {
            const res = await api.get("dashboard");

            //
            let data = res.data.result;
            //

            //
            commit("SET_DASHBOARD_DATA", data);
            //
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    },

    async getThirdPartyToken({ commit }, payload) {
        try {
            const res = await api.get("user/third-party-login", {
                params: {
                    id: payload
                }
            });

            //
            let url = res.data.result;

            //
            return url;
        } catch (error) {
            return false;
        }
    },

    async checkUserExist({ commit }, payload) {
        try {
            const res = await api.get('user/exists', {
                params: {
                    address: payload
                }
            });

            if (res.data.result === -1) {
                return false
            }
            return true
        } catch (error) {
            return false
        }
    },

    async migrateWallet({ commit }, payload) {
        try {
            const { data } = await api.get("user/migrate", {
                params: {
                    newAddress: payload
                }
            });

            //
            const { message, result } = data;

            //
            return { message, result };
        } catch (error) {
            return false;
        }
    },

    async migrateBalance({ commit }, payload) {
        try {
            const { data } = await api.post("user/migrate-ecg", payload);

            //
            const { message, result } = data;

            //
            return { message, result };
        } catch (error) {
            return false;
        }
    },

    async migrateNFTs({ commit }, payload) {
        try {
            const { data } = await api.post("user/migrate-nft", payload);

            //
            const { message, result } = data;

            //
            return { message, result };
        } catch (error) {
            return false;
        }
    },

    async sendTransferHash({ commit }, payload) {
        try {
            await api.post("payments/set-payment-info", payload);

            return true;
        } catch (error) {
            return false;
        }
    },

    async getTransferHash({ commit }) {
        try {
            const { data } = await api.get("payments/get-payment-info");

            return data.result;
        } catch (error) {
            return false;
        }
    }
};
/*=========================================================================================
  File Name: Contract.js
  Description: Contract Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    contractInfo: state => state.contractInfo ? state.contractInfo : JSON.parse(sessionStorage.getItem('contract'))
};
/*=========================================================================================
  File Name: ticket.js
  Description: Ticket
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './netificationModuleState.js'
import mutations from './netificationModuleMutations.js'
import getters from './netificationModuleGetters.js'
import actions from './notificationModuleActions.js'

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    SET_AUTH(state, responce) {
        return (state.auth = responce);
    },

    SET_USER(state, responce) {
        return (state.user = responce);
    },

    SET_TOKEN(state, accessToken) {
        return (state.token = accessToken);
    },

    LOGOUT(state) {
        state.auth = "";
        state.user = '';
        state.token = '';
        sessionStorage.removeItem('token')
            // sessionStorage.removeItem('defaultAddress')
    },

    SET_DASHBOARD_DATA(state, responce) {
        return (state.dashboard = responce);
    }
};
/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule =>editorh user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
import auth from "@/auth/authService";

import firebase from "firebase/app";
import "firebase/auth";

import authMiddleware from "./utils/authMiddleware.js";

Vue.use(Router);

const router = new Router({
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [{
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: "",
            component: () =>
                import ("./layouts/main/Main.vue"),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: "/",
                    redirect: "/dashboard"
                },
                {
                    path: "/dashboard",
                    name: "dashboard",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("./views/apps/dashboard/Dashboard.vue"),
                    meta: {
                        rule: "editor"
                    }
                },
                {
                    path: "/payment/invesments",
                    name: "Invesment",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("./views/apps/payment/Invesments.vue"),
                    meta: {
                        rule: "editor"
                    }
                },
                {
                    path: "/payment/benefits",
                    name: "Benefits",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("./views/apps/payment/Benefits.vue"),
                    meta: {
                        rule: "editor"
                    }
                },
                {
                    path: "/freeze-ecg",
                    name: "FreezeECG",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("./views/apps/freeze-ecs/FreezeECS.vue"),
                    meta: {
                        rule: "editor"
                    }
                },
                {
                    path: "/withdraws",
                    name: "Withdraws",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("./views/apps/withdraws/index.vue"),
                    meta: {
                        rule: "editor"
                    }
                },
                {
                    path: "/network",
                    name: "network",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("./views/apps/network/index.vue"),
                    meta: {
                        rule: "editor"
                    }
                },
                {
                    path: "/network/:id",
                    name: "networkDetail",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("./views/apps/network/NetworkDetail.vue"),
                    meta: {
                        rule: "editor"
                    }
                },
                {
                    path: "/support-center",
                    name: "Support",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("./views/apps/supoort/SupportCenter.vue"),
                    meta: {
                        rule: "editor"
                    }
                },
                // {
                //     path: "/migrate-ticket",
                //     name: "migrate-ticket",
                //     component: () =>
                //         import ("./views/apps/chat/Support.vue"),
                //     meta: {
                //         rule: "editor"
                //     }
                // },
                // {
                //     path: "/migrate",
                //     name: "migrate",
                //     beforeEnter: authMiddleware,
                //     component: () =>
                //         import ("./views/apps/migrate/index.vue"),
                //     meta: {
                //         rule: "editor"
                //     }
                // },
                {
                    path: "/faqs",
                    name: "FAQs",
                    component: () =>
                        import ("./views/apps/faq/FAQ.vue"),
                    meta: {
                        rule: "editor"
                    }
                }
            ]
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: "",
            component: () =>
                import ("@/layouts/full-page/FullPage.vue"),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================
                {
                    path: "/login",
                    name: "login",
                    component: () =>
                        import ("@/views/pages/login"),
                    meta: {
                        rule: "editor"
                    }
                },
                {
                    path: "/register",
                    name: "page-payment",
                    component: () =>
                        import ("@/views/pages/register"),
                    meta: {
                        rule: "editor"
                    }
                },
                {
                    path: "/register-faqs",
                    name: "register-faqs",
                    component: () =>
                        import ("@/views/pages/faq/index.vue"),
                    meta: {
                        rule: "editor"
                    }
                },
                {
                    path: "/google-authentication-help",
                    name: "google-help",
                    component: () =>
                        import ("@/views/pages/googleAuthenticatorFAQ/index.vue"),
                    meta: {
                        rule: "editor"
                    }
                },
                {
                    path: "/exchange",
                    name: "exchange",
                    component: () =>
                        import ("@/views/pages/exchange/index.vue"),
                    meta: {
                        rule: "editor"
                    }
                },
                //{
                //    path: "/sync-ecs",
                //    name: "sync",
                //    component: () =>
                //        import ("@/views/pages/sync/index.vue"),
                //    meta: {
                //        rule: "editor"
                //    }
                //},
                {
                    path: "/pages/error-404",
                    name: "page-error-404",
                    component: () =>
                        import ("@/views/pages/Error404.vue"),
                    meta: {
                        rule: "editor"
                    }
                },
                {
                    path: "/pages/error-500",
                    name: "page-error-500",
                    component: () =>
                        import ("@/views/pages/Error500.vue"),
                    meta: {
                        rule: "editor"
                    }
                }
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: "*",
            redirect: "/pages/error-404"
        }
    ]
});

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

router.beforeEach((to, from, next) => {
    firebase.auth().onAuthStateChanged(() => {
        // get firebase current user
        const firebaseCurrentUser = firebase.auth().currentUser;

        // if (
        //     to.path === "/pages/login" ||
        //     to.path === "/pages/forgot-password" ||
        //     to.path === "/pages/error-404" ||
        //     to.path === "/pages/error-500" ||
        //     to.path === "/pages/register" ||
        //     to.path === "/callback" ||
        //     to.path === "/pages/comingsoon" ||
        //     (auth.isAuthenticated() || firebaseCurrentUser)
        // ) {
        //     return next();
        // }

        // If auth required, check login. If login fails redirect to login page
        if (to.meta.authRequired) {
            if (!(auth.isAuthenticated() || firebaseCurrentUser)) {
                router.push({ path: "/pages/login", query: { to: to.path } });
            }
        }

        return next();
        // Specify the current path as the customState parameter, meaning it
        // will be returned to the application after auth
        // auth.login({ target: to.path });
    });
});

export default router;

import api from "../../axios";
export default {
    setNotification({ commit }, payload) {
        commit("setNotification", payload);
    },

    showNotification({ commit }) {
        commit("showNotification");
    },

    hideNotification({ commit }) {
        commit("hideNotification");
    },
    async getMessages({ commit }) {
        try {
            const { data } = await api.get("message/index");

            let messages = data.result;
            commit("");

            return messages;
        } catch (error) {
            return false;
        }
    },

    async removeMessage(_, payload) {
        try {
            await api.get(`message/read?id=${payload}`);

            return true;
        } catch (error) {
            return false;
        }
    },
};
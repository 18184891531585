/*=========================================================================================
  File Name: tronBotMutations.js
  Description: Tron Bot Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
==========================================================================================*/


export default {
    setTronWebObject(state, tronWeb) {
        state.tronWebObject = tronWeb
    },
    setTronAccessStatus(state, hasAccess) {
        state.isTronAccessible = hasAccess
    },
    setTronConnectionStatus(state, isConnected) {
        state.isTronConnected = isConnected
    },
    setDefaultAddress(state, defaultAddr) {
        state.defaultAddress = defaultAddr
    },
    setLoginStatus(state, isLogin) {
        state.loginStatus = isLogin
    },
}
/*=========================================================================================
  File Name: tronBotState.js
  Description: Tron Bot State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    tronWebObject: {},
    isTronAccessible: false,
    isTronConnected: "",
    defaultAddress: {},
    base58: "",
    loginStatus: '',
};
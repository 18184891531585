/*=========================================================================================
  File Name: paymentGetters.js
  Description: Payment Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    invests(state) {
        return state.invests;
    },
    investMine(state) {
        return state.investMine;
    },
    benefits(state) {
        return state.benefits;
    },
    benefitsMine(state) {
        return state.benefitsMine;
    },
    withdraws(state) {
        return state.withdraws;
    },
    withdrawsMine(state) {
        return state.withdrawsMine;
    },
    availableBalance(state) {
        return state.availableBalance;
    },
    burnReason(state) {
        return state.burnReason;
    },
    apps(state) {
        return state.apps;
    },
    investInfo(state) {
        return state.investInfo
    },
    ECS_Approved(state) {
        return state.ECS_Approved ? state.ECS_Approved : localStorage.getItem('ECS_Approved')
    },
    USDT_Approved(state) {
        return state.USDT_Approved ? state.USDT_Approved : localStorage.getItem('USDT_Approved')
    }
};
/*=========================================================================================
  File Name: ticketMutations.js
  Description: Ticket Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    SET_NETWORK(state, responce) {
        return (state.network = responce);
    },

};
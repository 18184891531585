/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    user: null,
    auth: {},
    token: null,
    dashboard: {},
    socials: [{
            name: "telegram",
            link: "https://telegram.org/"
        },
        {
            name: "instagram",
            link: "https://www.instagram.com/"
        },
        {
            name: "twitter",
            link: "https://twitter.com/"
        }
    ]
};
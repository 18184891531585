/*=========================================================================================
  File Name: ticketGetters.js
  Description: Ticket Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    departments: state => state.departments,
    tickets: state => state.tickets,
    selectedTicket: state => state.selectedTicket,
    lastPage: state => state.lastPage
};
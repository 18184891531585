import api from '../../axios'

export default {
    // Get Contract Info & Min Invesment Values
    async getBaseInfo({ commit }) {
        try {
            const res = await api.get("get-basic-info");

            //
            let result = res.data.result;

            //
            sessionStorage.setItem('contract', JSON.stringify(result))

            // 
            commit('SET_CONTRACT_INFO', result)

            //
            return true
        } catch (error) {
            console.error(error);
        }
    }
};
/*=========================================================================================
  File Name: moduleAuthGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    token: state => (state.token ? state.token : sessionStorage.getItem("token")),
    user: state => state.user ? state.user : JSON.parse(sessionStorage.getItem('user')),
    auth: state => state.auth,
    dashboard: state => state.dashboard,
    socials: state => state.socials
};
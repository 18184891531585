/*=========================================================================================
  File Name: paymentState.js
  Description: Payment State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    invests: [],
    investMine: "",
    benefits: [],
    benefitsMine: "",
    withdraws: [],
    withdrawsMine: "",
    availableBalance: "",
    investInfo: {},
    burnReason: [{
            value: 1,
            label: "Qualified leadership fee"
        },
        {
            value: 2,
            label: "Qualified Ambassador fee"
        },
        {
            value: 0,
            label: "other"
        }
    ],

    apps: [
        //{
        //    value: 1,
        //    label: "Ancestry"
        //},
        {
            value: 2,
            label: "Trade Center"
        },
        //{
        //    value: 3,
        //    label: "Backgammon"
        //},
        {
            value: 4,
            label: "Lands Of Azolite"
        },
        {
            value: 5,
            label: "Virtualand"
        }
    ],

    ECS_Approved: "",
    USDT_Approved: ""
};

/*=========================================================================================
  File Name: payment.js
  Description: Payment
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./modulePaymentState.js";
import mutations from "./modulePaymentMutations.js";
import actions from "./modulePaymentActions.js";
import getters from "./modulePaymentGetters.js";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
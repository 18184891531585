/*=========================================================================================
  File Name: tronBotGetters.js
  Description: Tron Bot Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
==========================================================================================*/

export default {
    getTronWebObject(state) {
        return state.tronWebObject;
    },
    getTronAccessStatus(state) {
        return state.isTronAccessible;
    },
    getTronConnectionStatus(state) {
        return state.isTronConnected;
    },
    getDefaultAddress(state) {
        return state.defaultAddress ? state.defaultAddress : JSON.parse(sessionStorage.getItem("defaultAddress"));
    },
    getLoginStatus(state) {
        return state.loginStatus;
    }
};
// /*=========================================================================================
//   File Name: ticketActions.js
//   Description: Ticket Actions
//   ----------------------------------------------------------------------------------------
//   Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
//   Author: Pixinvent
//   Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================*/

import api from "../../axios";

export default {
    // Get Departments
    async getDepartments({ commit }) {
        try {
            const res = await api.get("ticket/departments");

            //
            let departments = res.data.result;
            //

            //
            commit("SET_DEPARTMENTS", departments);
            //
        } catch (error) {
            console.error(error);

        }
    },
    //

    // Get Ticket List
    async getTickets({ commit }, payload) {
        try {
            const res = await api.get("ticket", {
                params: payload
            });

            //
            let tickets = res.data.result.data;
            let lastPage = res.data.result.last_page;
            //

            //
            commit("SET_TICKETS", tickets);
            commit("SET_TICKET_LASTPAGE", lastPage);
            //
        } catch (error) {
            console.error(error);
        }
    },

    // Set New Ticket
    async setNewTicket({ commit }, payload) {
        try {
            const res = await api.post("ticket/store", payload);

            //
            let ticket = res.data.result;
            //

            //
            commit("SET_NEW_TICKET", ticket);
            //

            return true;
        } catch (error) {
            console.error(error);
        }
    },

    // Show Specific Ticket
    async showTicket({ commit }, payload) {
        try {
            const res = await axios.get(`ticket/${payload}/show`);

            //
            let ticket = res.data.result;
            //

            //
            commit("SHOW_TICKET", ticket);
            //
        } catch (error) {
            console.error(error);
        }
    },
    //

    // Send Answer
    async sendReply({ commit }, payload) {
        try {
            const res = await axios.post(
                `ticket/${payload.id}/reply`,
                payload.content
            );

            //
            let answer = res.data.result;
            //

            //
            commit("SET_NEW_ANSWER", answer);
            //
        } catch (error) {
            console.error(error);
        }
    }
    //
};
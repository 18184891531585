/*=========================================================================================
  File Name: ticketMutations.js
  Description: Ticket Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    setNotification(state, payload) {
        state.notification = payload
    },

    showNotification(state) {
        state.status = true
    },

    hideNotification(state) {
        state.status = false
    }
};
/*=========================================================================================
  File Name: paymentMutations.js
  Description: Payment Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    setInvests(state, responce) {
        return (state.invests = responce);
    },

    setInvestMine(state, responce) {
        return (state.investMine = responce);
    },

    setBenefits(state, responce) {
        return (state.benefits = responce);
    },

    setBenefitsMine(state, responce) {
        return (state.benefitsMine = responce);
    },

    setWithdraws(state, responce) {
        return (state.withdraws = responce);
    },

    setWithdrawsMine(state, responce) {
        return (state.withdrawsMine = responce);
    },

    setAvailableBalance(state, responce) {
        return (state.availableBalance = responce);
    },

    setInvestInfo(state, responce) {
        return (state.investInfo = responce);
    },

    // Set Approved Amounts
    setApprovedAmounts(state, payload) {
        localStorage.setItem("ECS_Approved", payload.ECS);
        localStorage.setItem("USDT_Approved", payload.USDT);

        //
        state.ECS_Approved = payload.ECS;
        state.USDT_Approved = payload.USDT;
    }
};